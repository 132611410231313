import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path: '/home',
    component: () => import('../views/home/home.vue'),
    redirect:'/shouye',
    children:[
      {
        path:'/shouye',
        component:()=>import("@/views/home/shouye.vue"),
      },
      {
        path:'/wenjuan',
        component:()=>import("@/views/home/wenjuan.vue"),
      },
      {
        path:'/about',
        component:()=>import("@/views/home/about.vue"),
      },
      {
        path:'/shuziren',
        component:()=>import("@/views/home/shuziren.vue"),
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
