<template>
  <div>
   <router-view></router-view>
  </div>
</template>
<script>

    export default {
        data(){
            return {
               
            }
        },
        created(){
            
        },
        methods:{
      
        }
    }
</script>
<style lang="scss">

</style>
